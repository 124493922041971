@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* text-decoration: none; */
}

#root {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
#active {
  color: #07bc0c;
}

.slider {
  height: fit-content;
}

@media (max-width: 640px) {
  .slider {
    height: 20rem; /* Height for medium screens (md) */
  }
}
@media (max-width: 768px) {
  .slider {
    height: 25rem; /* Height for medium screens (md) */
  }
}
@media (max-width: 400px) {
  .slider {
    height: 18rem; /* Height for medium screens (md) */
  }
}
